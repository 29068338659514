import * as React from 'react';
import {useState} from 'react';
import {createTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {useActionData, useNavigate, useNavigation, useSearchParams, useSubmit} from "react-router-dom"
import {
    CancelButton,
    FieldErrorBox,
    Form,
    FormErrorBox,
    FullScreenDialog,
    SubmitButton,
    TextInput
} from "@profusion.media/profusion-ui"
import {Card, CardActions, CardContent, Stack} from "@mui/material";
import {MutationPasswordResetArgs} from "@profusion.media/profusion-iam-user";
import Typography from "@mui/material/Typography";

const theme = createTheme();

export default function PasswordLost() {
    const {t} = useTranslation()
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()
    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'
    const [searchParams] = useSearchParams()

    const [data, setData] = useState<MutationPasswordResetArgs>({
        emailOrPhone: searchParams.get("e") ?? "",
        language: "fr-FR"
    })

    const handleSubmit = () => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const onChange = (name: string, value: string) => {
        setData((prev: any) => ({...prev, [name]: value}))
    }

    return <FullScreenDialog title="Mot de passe oublié" onClose={handleCancel}>
        <Form onSubmit={handleSubmit}
              onCancel={handleCancel}
              updating={loading}
              errors={validationErrors}
        >
            <FormErrorBox message="Des erreurs sont survenues"/>
            <Card>
                <CardContent>
                    <Stack spacing={4}>
                        <Typography variant="body2" gutterBottom>
                            Indiquez l'adresse e-mail que vous utilisez
                            pour vous connecter, vous recevrez un code
                            d'ici quelques instants. Si besoin, veuillez
                            consulter votre boîte de SPAM.
                        </Typography>
                        <TextInput name="emailOrPhone"
                                   type="email"
                                   value={data.emailOrPhone}
                                   onValueChange={onChange}
                                   label="E-mail"
                        />
                        <FieldErrorBox field="emailOrPhone" errorCode="ValidationException">
                            {t("account.lostpassword.notfound", "Cette adresse email n'a pas été trouvée")}
                        </FieldErrorBox>
                    </Stack>
                </CardContent>
                <CardActions sx={{flexDirection: "row-reverse"}}>
                    <SubmitButton label={t("lostPassword.submit.label", "Envoyer")}/>
                    <CancelButton/>
                </CardActions>
            </Card>
        </Form>
    </FullScreenDialog>
}
