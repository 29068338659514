import {Box, Collapse, ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrayingHands} from "@fortawesome/pro-solid-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {CommunityMembership} from "@graph";
import {useTranslation} from "react-i18next";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {Mouvement247} from "@components/layout/dashboard/drawer/content/Mouvement247";
import List from "@mui/material/List";

export interface UserPrayerMenuProps {
    membership: CommunityMembership[]

}

export const UserPrayerMenu = (props: UserPrayerMenuProps) => {
    const {membership} = props
    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();

    return <Box sx={open && {
        backgroundColor: "#f4f4f4",
        marginLeft: "4px",
        marginRight: "4px",
        borderRadius: 2,
    }}>
        <Box sx={open && {
            marginLeft: "-4px",
            marginRight: "-4px",
        }}>
            <ListItem
                onClick={(event) => {
                setOpen(!open)
                event.stopPropagation()
                event.preventDefault()
            }}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faPrayingHands}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.prayer", "Prière")}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse in={open} timeout="auto">
                <List component="div" dense disablePadding
                      sx={{pl: 9}}
                >

                    <ListItem onClick={_ => navigate("/groups/prayer/mine")}>
                        <ListItemText primary="Groupes personnels"/>
                    </ListItem>
                    <ListItem onClick={_ => navigate("/groups/prayer/shared")}>
                        <ListItemText primary="Groupes partagés"/>
                    </ListItem>
                    <Mouvement247 membership={membership}/>
                    <ListItem onClick={_ => navigate("/groups/prayer/favorites")}>
                        <ListItemText primary="En cours"/>
                    </ListItem>
                </List>
            </Collapse>
        </Box>
    </Box>

}