import {Group} from "@profusion.media/profusion-core-group";
import {Await, useLoaderData} from "react-router-dom";
import {Grid} from "@mui/material";
import {GroupListTitle} from "./components/GroupListTitle";
import {LoadListReturn} from "./loaders";
import {Suspense, useContext} from "react";
import {GroupListSkeleton} from "./components/GroupListSkeleton";
import {GroupListItem} from "./components/GroupListItem";
import {HomeEmptyGroupsMine} from "../../HomeScreen/components/HomeEmptyGroupsMine";
import {HomeEmptyGroupsShared} from "../../HomeScreen/components/HomeEmptyGroupsShared";
import {DashboardAppContext} from "@groups/DashboardApp";


export const GroupList = () => {
    const {groups, what} = useLoaderData() as Awaited<LoadListReturn>
    const {setBottomTab} = useContext(DashboardAppContext)

    setBottomTab(what === 'mine' ? 0 : 1)

    return <div>
        <GroupListTitle what={what}/>

        <Suspense fallback={<GroupListSkeleton count={5}/>}>
            <Await resolve={groups}>
                {({data}) => <Grid id="groupGrid" container columnSpacing={1}

                >
                    {data.length
                        ? data.map((group: Group) => <Grid item xs={12} sm={6} md={4}>
                            <GroupListItem group={group} key={group.hid}/>
                        </Grid>)
                        : what === "mine"
                            ? <HomeEmptyGroupsMine/>
                            : <HomeEmptyGroupsShared/>}
                </Grid>}
            </Await>
        </Suspense>
    </div>
}