import * as React from 'react';
import {startTransition, useEffect, useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useNavigate} from "react-router-dom";
import {faHome,faPrayingHands, faPlusCircle, faShield, faStars, faUserGroup} from "@fortawesome/pro-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useConnectedUser} from "@profusion.media/profusion-iam-user";
import {
    DrawerCommunityMembershipMenu
} from "@components/layout/dashboard/drawer/community/DrawerCommunityMembershipMenu";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import {Box, Divider, ListItem} from "@mui/material";
import {UserContentMenu} from "@components/layout/dashboard/drawer/content/UserContentMenu";
import {useDrawerMenu} from "@profusion.media/profusion-ui";
import {faSignOutAlt} from "@fortawesome/pro-light-svg-icons";
import {communityMembershipQuery} from "@community/loaders/communityMembershipLoader";
import profusion from "@config/profusion";
import {CommunityMembership} from "@graph";
import {UserPrayerMenu} from "@components/layout/dashboard/drawer/content/UserPrayerMenu";

export const DashboardDrawerContent = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const user = useConnectedUser()
    const membershipQuery = communityMembershipQuery(profusion)
    const [membership, setMembership] = useState<CommunityMembership[]>([])

    useEffect(() => {
        membershipQuery.then(result => {
            setMembership(result.data || [])
        })
    }, [membershipQuery])
    
    return <React.Fragment>
        <List subheader={<ListSubheader>Mon espace</ListSubheader>}>
            <ListItem onClick={_ => startTransition(() => {
                navigate("/dashboard")
            })}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faHome}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.home", "Accueil")}/>
            </ListItem>

            <UserPrayerMenu membership={membership} />
            
            
            <ListItem onClick={_ => navigate("/groups/share/mine")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faStars}/>
                </ListItemIcon>
                <ListItemText primary={t("prayer.menu.discover", "Découvrir Jésus")}/>
            </ListItem>


            <UserContentMenu/>


            <DrawerCommunityMembershipMenu membership={membership} />

            <Divider />
            <ListItem onClick={_ => navigate("/logout")}>
                <ListItemIcon>
                    <FontAwesomeIcon icon={faSignOutAlt}/>
                </ListItemIcon>
                <ListItemText primary={t("menu.signout", "Déconnexion")}/>
            </ListItem>

        {user?.isSuperuser && <>
            <Divider />
            <ListItemButton onClick={_ => navigate("/su/denomination/1/1000")}>
                <ListItemIcon>
                    <FontAwesomeIcon color="#990000" icon={faShield}/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={
                    {color: "#990000"}
                } primary={t("menu.super_admin.label", "Super Admin")}/>
            </ListItemButton>
            </>
        }

        </List>
    </React.Fragment>
}
