import {GroupSettingsForm} from "@groups/Groups/components/settings";
import {Await, useActionData, useNavigate, useNavigation, useParams, useSubmit} from "react-router-dom";
import {ValidationErrors} from "@profusion.media/profusion-core";
import {Suspense, useState} from "react";
import {useCommunityDetailsLoader} from "@community/loaders/communityDetailsLoader";
import {FullScreenDialog} from "@profusion.media/profusion-ui";
import {TabContext, TabList} from "@mui/lab";
import {Tab} from "@mui/material";
import {WhiteBox} from "@components/WhiteBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsersClass} from "@fortawesome/pro-regular-svg-icons";
import {faPrayingHands} from "@fortawesome/pro-light-svg-icons";

export const CommunityGroupCreate = () => {
    const {community} = useCommunityDetailsLoader("communityDetailsAdmin")
    const {group_type} = useParams()

    const [feature, setFeature] = useState(group_type)
    const submit = useSubmit()
    const validationErrors = useActionData() as ValidationErrors
    const navigation = useNavigation()

    const navigate = useNavigate()
    const loading = navigation.state === 'submitting'


    const handleSubmit = (data: any) => {
        submit(data, {
            method: "post",
            encType: "application/json"
        })
    }

    const handleCancel = () => {
        navigate('..')
    }

    return <Suspense>
        <Await resolve={community}>
            {({data: c}) =>
                <FullScreenDialog
                    title="Nouveau Groupe"
                    onClose={handleCancel}
                >
                    <WhiteBox title="Type de groupe">
                        <TabContext value={feature}>
                            <TabList onChange={(e, v) => setFeature(v)}>
                                <Tab icon={<FontAwesomeIcon icon={faUsersClass}/>} label="Partage" value="share"/>
                                <Tab icon={<FontAwesomeIcon icon={faPrayingHands}/>} label="Prière" value="prayer"/>
                            </TabList>
                        </TabContext>
                    </WhiteBox>
                    <GroupSettingsForm
                        group={{
                            title: "",
                            language: c.language ?? "",
                            feature,
                            welcome: "",
                            communityHid: c.hid
                        }}
                        community={c}
                        mode="create"
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={loading}
                        validationErrors={validationErrors}/>
                </FullScreenDialog>
            }
        </Await>
    </Suspense>
}