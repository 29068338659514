import {redirect, RouteObject} from "react-router-dom";
import {CommunityLayout} from "@community/layout/CommunityLayout";
import {loadCommunityDetails, loadCommunityOrganization} from "@community/loaders/communityDetailsLoader";
import {actionCommunityJoin} from "@community/actions/community_join";
import {CommunityAdmin} from "@community/screens/admin/CommunityAdmin";
import {loadCommunityMembers} from "@community/loaders/communityMembersLoader";
import {CommunityGroupsAdmin} from "@community/screens/groups/CommunityGroupsAdmin";
import React from "react";
import {CommunityGroupCreate} from "@community/screens/groups/edit/CommunityGroupCreate";
import {CommunityGroupUpdate} from "@community/screens/groups/edit/CommunityGroupUpdate";
import {CommunityMembersList} from "@community/screens/members/CommunityMemberList";
import {actionCommunityInvite} from "@community/actions/community_invite";
import {CommunityInvitation} from "@community/screens/InviteCommunity/CommunityInvitation";
import {CommunityInvitationQR} from "@community/screens/InviteCommunity/CommunityInvitationQR";
import {CommunityHome} from "@community/screens/home/CommunityHome";
import {actionCreate, actionUpdate} from "@groups/Groups/components/settings";
import {OrganizationUpdate} from "@organizations/screens/edit/OrganizationUpdate";
import {actionCommunityOrganizationUpdate} from "@community/actions/organization/community_organization_edit";
import {CommunityGroupListTabs} from "@community/screens/groups/list/CommunityGroupListTabs";
import {DetailsLoaderProps, loadGroup} from "@groups/Groups/GroupDetails/loaders";


export const CommunityRouter: RouteObject[] = [
    {
        path: "",
        element: <CommunityLayout/>,
        children: [
            {
                path: ":community",
                id: "communityDetails",
                loader: loadCommunityDetails,
                action: actionCommunityJoin,
                element: <CommunityHome/>,
            },
        ]
    },
    {
        path: "admin/:community",
        element: <CommunityAdmin/>,
        id: "communityDetailsAdmin",
        loader: loadCommunityDetails,
        children: [
            {
                path: "",
                loader: () => redirect("members")
            },
            {
                path: "members",
                index: true,
                loader: loadCommunityMembers,
                element: <CommunityMembersList/>,
                handle: {
                    "tab": "0"
                },
            },
            {
                path: "settings",
                loader: loadCommunityOrganization,
                action: actionCommunityOrganizationUpdate(),
                element: <OrganizationUpdate/>,
                handle: {
                    "tab": "3"
                },
            },
            {
                path: "invitation",
                element: <CommunityInvitation/>,
                action: actionCommunityInvite
            },
            {
                path: "qr",
                element: <CommunityInvitationQR/>
            },
            {
                path: "groups/:group_type",
                element: <CommunityGroupsAdmin/>,
                handle: ({params}) => ({
                    "tab": params.group_type === "prayer" ? 2 : 1
                }),
                children: [
                    {
                        path: "",
                        element: <CommunityGroupListTabs/>
                    },
                    {
                        path: "add",
                        element: <CommunityGroupCreate/>,
                        action: (props) => actionCreate(props)
                    },
                    {
                        path: ":hid/update",
                        loader: (props: DetailsLoaderProps) => loadGroup(props),
                        element: <CommunityGroupUpdate/>,
                        action: actionUpdate
                    }
                ]
            },
        ]
    }
]