import {CommunityMembership} from "@graph";
import {useNavigate} from "react-router-dom";
import {ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

interface M247Props {
    membership: CommunityMembership[]
}

export const Mouvement247 = (props: M247Props) => {
    const {membership} = props;
    const hasMembership = membership.find(m => m.communityHid === 'c:eb9aOeBGwjx')
    const navigate = useNavigate()

    const handleJoin = () => {
        window.open("https://www.aebeq.qc.ca/mouvementdepriere/");
    }

    const handleGroup = () => {
        navigate(`/groups/prayer/${hasMembership.prayerGroupHid}`, {
            state: {
                from: window.location.pathname
            }
        })
    }

    if (hasMembership) {
        return <ListItem onClick={_ => handleGroup()}>

            <ListItemText primary={"Mouvement 24/7"}/>
        </ListItem>
    }

    return <ListItem onClick={_ => handleJoin()}>

        <ListItemText primary={"Rejoindre le mouvement 24/7"}
        />
    </ListItem>
}
