import {communityMembershipQuery} from "@community/loaders/communityMembershipLoader";
import React, {useEffect, useState} from "react";
import profusion from "@config/profusion";
import {CommunityMembership} from "@graph";
import {ListItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListItemText from "@mui/material/ListItemText";
import {faChurch} from "@fortawesome/pro-light-svg-icons";


export interface CommunityMembershipProps {
    membership: CommunityMembership[];
}

export const DrawerCommunityMembershipMenu = (props: CommunityMembershipProps) => {
    const {membership} = props;
    const navigate = useNavigate()


    if (membership.length === 0) return

    const handleGoAdmin = (hid: string) => {
        navigate(`/community/admin/${hid}/members`)
    }
    const handleGoCommunity = (hid: string) => {
        navigate(`/community/${hid}`)
    }

    return <>
        {membership.map((member: CommunityMembership) => (member.state == 'A_10' || member.isAdmin) &&
            <>
                {member.isAdmin ? <>
                    <ListItem onClick={_ => handleGoAdmin(member.communityHid)}>
                        <ListItemIcon>
                            <FontAwesomeIcon icon={faChurch} fixedWidth/>
                        </ListItemIcon>
                        <ListItemText primary={"Gérer mon église"}
                                      secondary={member.communityName}
                        />
                    </ListItem>
                </> : <></>}

            </>
        )}
    </>
}