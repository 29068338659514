import React from "react"
import {User} from "../users/schema";

export interface ConnectedUserProps {
  isConnected: boolean
  user?: User
  roles: Array<string>
  reload: () => void
}

export const defaultConnectedUserProps : ConnectedUserProps = {
  isConnected: false,
  roles: [],
  reload: () => {}
}

export interface ConnectedUserProviderProps {
  children: React.ReactNode | React.ReactNode[]
  onSignOut?: () => void
  waitElement?: React.ReactNode
}
