import {useNavigate, useParams} from "react-router-dom";
import {faRectangleHistoryCircleUser,faPray, faThumbTack as faBookmark, faUserGroup} from "@fortawesome/pro-solid-svg-icons"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DashboardAppContext} from "@groups/DashboardApp";
import {communityMembershipQuery} from "@community/loaders/communityMembershipLoader";
import profusion from "@config/profusion";
import {CommunityMembership} from "@graph";


export const GroupsBottomNavigation = () => {
    const context = useContext(DashboardAppContext)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {group_type} = useParams()
    const membershipQuery = communityMembershipQuery(profusion)
    const [membership, setMembership] = useState<CommunityMembership>(undefined)
    const [m247, setM247] = useState<string>("https://www.aebeq.qc.ca/mouvementdepriere/")

    useEffect(() => {
        membershipQuery.then(result => {
            const ms = result.data.find(m => m.communityHid === 'c:eb9aOeBGwjx')
            setMembership(ms)
            if (ms) setM247(`/groups/prayer/${ms.prayerGroupHid}`)
        })
    }, [membershipQuery])


    let paths = [
        `/groups/${group_type}/mine`,
        `/groups/${group_type}/shared`,
        m247,
        `/groups/${group_type}/favorites`,
    ]


    const navigateTo = (idx: number) => {
        if (paths[idx].startsWith("https:")) {
            window.open(paths[idx], "_blank")
        } else {
            navigate(paths[idx])
        }
    }

    return <BottomNavigation
        showLabels
        value={context.bottomActiveTab}
        onChange={(event, newValue) => {
            context.setBottomTab(newValue);
            navigateTo(newValue)
        }}
        className="bottom-navigation"
    >
        <BottomNavigationAction
            label={t("prayer.menu.mygroups", "Personnel")}
            icon={<FontAwesomeIcon icon={faUserGroup} fixedWidth/>}/>
        <BottomNavigationAction
            label={t("prayer.menu.shared", "Partagés")}
            icon={<FontAwesomeIcon icon={faRectangleHistoryCircleUser} fixedWidth/>}/>

        {group_type === 'prayer' && <BottomNavigationAction
            label={t("prayer.menu.m247", "24/7")}
            icon={<FontAwesomeIcon icon={faPray} fixedWidth/>}/>}

        {group_type === 'prayer' && <BottomNavigationAction
            label={t("prayer.menu.pinned", "En cours")}
            icon={<FontAwesomeIcon icon={faBookmark} fixedWidth/>}/>}

    </BottomNavigation>
}