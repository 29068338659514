import {
    ALL_GROUP_OVERVIEW_QUERY,
    Group,
    GROUP_CREATE_MUTATION,
    GROUP_LIST_MINE_QUERY,
    GroupCreateInput
} from "../schema";
import {executeMutation, ProfusionClient} from "@profusion.media/profusion-core";
import {AvatarCreateInput, MutationGroupCreateArgs} from "@profusion.media/graph";
import {GET_COMMUNITY_GROUPS} from "@community/schema/queries/get_community_details";

export type ActionCreateGroupResponse = {
    group: Group
}

export const actionCreateGroup = (client: ProfusionClient, group: GroupCreateInput, avatar?: AvatarCreateInput) => {
    return executeMutation<ActionCreateGroupResponse, MutationGroupCreateArgs>(client, GROUP_CREATE_MUTATION,
        {
            input: {
                title: group.title,
                language: group.language,
                feature: group.feature,
                welcome: group.welcome,
                organizationHid: group.organizationHid,
                communityHid: group.communityHid,
                tags: group.tags
            },
            avatar
        }, {
            isPrivate: true,
            refetchQueries: (data, variables) => {
                let queries: any = [{
                    query: GROUP_LIST_MINE_QUERY,
                    variables: {
                        type: data.group.mainFeature,
                    },
                    context: {
                        use_private: true
                    }
                }, {
                    query: ALL_GROUP_OVERVIEW_QUERY,
                    context: {
                        use_private: true
                    }
                }]

                if (data.group.communityHid) {
                    queries.push({
                        query: GET_COMMUNITY_GROUPS,
                        variables: {
                            hid: data.group.communityHid
                        },
                        context: {
                            use_private: true
                        }
                    })
                }

                return queries
            }
        })
}
